import React from "react"
import ReactPlayer from "react-player"

import src from "../../assets/videos/404.mp4"
import SEO from "../../components/seo"

const NotFoundPage = () => (
  <div className="not-found">
    <SEO title="404: Not found" />
    <div className="player-wrapper">
      <ReactPlayer
        className="react-player"
        url={src}
        width="100%"
        height="100%"
        playing={true}
        muted={true}
        loop={true}
      />
    </div>
  </div>
)

export default NotFoundPage
